import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

function LoginComponent() {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();

  const isOpenedFromInvitationLink =
    searchParams.has('invitation') && searchParams.has('organization');

  if (isOpenedFromInvitationLink) {
    loginWithRedirect({
      invitation: searchParams.get('invitation') || '',
      organization: searchParams.get('organization') || ''
    });
  }

  return (
    <div>
      <h1>Login</h1>
    </div>
  );
}

export const Login = LoginComponent;
