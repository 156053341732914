import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider, CacheLocation } from '@auth0/auth0-react';

import { App } from './app';

import { getConfig } from './config';

import reportWebVitals from './reportWebVitals';

import history from './utils/history';

import './custom.scss';

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  // ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  cacheLocation: 'localstorage' as CacheLocation,
  onRedirectCallback(appState: any) {
    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  }
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
