import { CallToActionProps } from './callToAction';
import type { HeadingProps } from './heading';
import type { LayoutProps } from './layout';
import type { PrimitiveProps } from './primitive';
import type { SelectProps } from './select';

export const ComponentTypes = [
  'CALL_TO_ACTION',
  'LAYOUT',
  'HEADING',
  'PRIMITIVE',
  'SELECT'
] as const;

export type ComponentType = typeof ComponentTypes[number];

export const NonPrimitiveComponentTypes = ComponentTypes.filter(
  (type): type is Exclude<ComponentType, 'PRIMITIVE'> => type !== 'PRIMITIVE'
);

export const PaddingPropertyTypes = ['top', 'right', 'bottom', 'left'] as const;

export type PaddingPropertyType = typeof PaddingPropertyTypes[number];

export type PaddingProperty = {
  [key in PaddingPropertyType]: number;
};

export interface PlacableComponent {
  id: string;
  type: ComponentType;
}

export type ProfileProperty = {
  userProperty: string;
};

export type EditableComponentProperties =
  | CallToActionProps
  | HeadingProps<string | ProfileProperty>
  | PrimitiveProps<ProfileProperty>
  | LayoutProps<Array<EditableComponentProperties>>
  | SelectProps<string | ProfileProperty>;

export type PlacableComponentProperties =
  | CallToActionProps
  | HeadingProps
  | PrimitiveProps
  | LayoutProps
  | SelectProps;
